<template lang="pug">
  BTab(
    v-bind="$props"
    v-on="$listeners"
    @click="viewed = true"
    :lazy="isLazy"
    :disabled="disabled"
  )
    template(v-slot:title)
      | {{ title }}
    slot
</template>

<script>
  import { BTab } from "bootstrap-vue"

  export default {
    props: {
      ...BTab.options.props,
      initialLazy: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isLazy() {
        return !this.viewed && this.initialLazy
      }
    },

    data() {
      return {
        viewed: false
      }
    }
  }
</script>

<style lang="sass" scoped></style>
